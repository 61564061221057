// Anything exported from this file is importable by other in-browser modules.
// @ts-nocheck
import jwt_decode from "jwt-decode";
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, min } from "rxjs";
export const str = "HEllo";
// export const web_url = "https://partners.digisevapay.in/";
export const web_url = window.location.href;


if (process.env.NODE_ENV === "development") { 
} else if (process.env.NODE_ENV === "production") { 
}
export const subuser = ['finance','support','on-borderding'];
export function refreshToken() {
  this.allowRefresh = false;
  // @ts-ignore
  const encUrl = AuthConfig.config.encodeUrl(
    "https://itpl.txninfra.com/logintokenrefresh.json"
  );
  // @ts-ignore
  this.http
    .post(encUrl, {})
    .pipe(
      // @ts-ignore
      finalize(() => {
        this.allowRefresh = true;
      })
    )
    .subscribe(
      (/** @type {{ token: string; }} */ res) => {
        // Replace Token
        sessionStorage.setItem("CORE_SESSION", res.token);
        // this.appService.autoLogOut(); // Refresh Logout Timer.
      },
      // @ts-ignore
      (/** @type {any} */ err) => { }
    );
}
// @ts-ignore
/**
 * @param {any} value
 */
export function sharedData(value) {
  let walletbalance = new BehaviorSubject(false);
  if (value == true) {
    walletbalance.next(true);
  }
}
/**
 * @param {number} numbervalue
 * @param {boolean} booleanvalue
 */
// @ts-ignore
export let isPrevRoute=new BehaviorSubject(0);
export let collapseValue = new BehaviorSubject(17);
export let collapseBooleanValue = new BehaviorSubject(0);
export function collapseBtn(numbervalue, booleanvalue) {
  collapseValue.next(numbervalue);
  collapseBooleanValue.next(booleanvalue);
}
/**
 * @param {number} numbervalue1
 * @param {boolean} booleanvalue1
 */
export let collapseValue1 = new BehaviorSubject(17);
export let collapseBooleanValue1 = new BehaviorSubject(0);
export function onlyForLeftNav(numbervalue1, booleanvalue1) {
  collapseValue1.next(numbervalue1);
  collapseBooleanValue1.next(booleanvalue1);
}
export let kycUpdateStatus = new BehaviorSubject();
export function statusUpdateFunction(randomvalue) {
  kycUpdateStatus.next(randomvalue);
}
let testBal;
export const shareBal = new BehaviorSubject("");
export function updateWallet(value) {
  testBal = value;
  shareBal.next(testBal);
}

export const newBal1 = new BehaviorSubject(0);
export async function getwallet1_balance() {
  let response = await fetch(
    "https://api-prod.txninfra.com/encrV3/wallet/common/wallet1fetchbalance",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
        pass_key: 'MQxplW763gfSDOwm2aLmCOFXQYGSWtsnvCfkat58j8AFl0xW'
      },
    }
  );
  let data = await response.json();
  data= decryptRequest(data.ResponseData, 'MWYztgF/ZcR+XyRwZEn1NpdyYGUnQMuIMDgI6ZWlaNg=');
  data=JSON.parse(data);
  if (data.status == 0) {
    newBal1.next(data.response.balance);
  } else {
    newBal1.next(0);
  }
}

export const newBal2 = new BehaviorSubject(0);
export async function getwallet2_balance() {
  let response1 = await fetch(
    "https://api-prod.txninfra.com/encrV3/wallet/common/wallet2fetchbalance",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
        pass_key: 'MQxplW763gfSDOwm2aLmCOFXQYGSWtsnvCfkat58j8AFl0xW'
      },
    }
  );
  let data = await response1.json();
  data = decryptRequest(data.ResponseData, 'MWYztgF/ZcR+XyRwZEn1NpdyYGUnQMuIMDgI6ZWlaNg='); 
  data=JSON.parse(data);
  if (data.status == 0) {
    newBal2.next(data.data.balance);
  } else {
    newBal2.next(0);
  }
}

export const newBal3 = new BehaviorSubject(0);
export async function getwallet3_balance() {
  let response2 = await fetch(
    "https://api-prod.txninfra.com/encrV3/wallet/common/api/wallet1fetchbalance",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
        pass_key: 'MQxplW763gfSDOwm2aLmCOFXQYGSWtsnvCfkat58j8AFl0xW'
      },
    }
  );
  let data = await response2.json();
  data = decryptRequest(data.ResponseData, 'MWYztgF/ZcR+XyRwZEn1NpdyYGUnQMuIMDgI6ZWlaNg='); 
  data=JSON.parse(data)
  if (data.status == 0) {
    newBal3.next(data.response.balance);
  } else {
    newBal3.next(0);
  }
}

export const mapArr = ["https://partners.mydigitalpayment.com/aeps"];
export const aadharRegArr = ["https://partners.mydigitalpayment.com/aadhar","https://localhost:4200/aadhar"];

export const Base_url = {
  auth_base: "https://oauth2-auth-server-common-admin-prod.txninfra.com/",
  user_base: "https://user-registration-common-admin-prod.txninfra.com/",
  transaction_base:
    "https://transactional-module-common-admin-prod.txninfra.com/",
  matm_mapping: "https://apiprod.txninfra.com/cprod/common_matm_map", //old url
  // matm_mapping: "https://apiprod.txninfra.com/cprodV2/common_matm_map", //new url
  prod_url: "https://apiprod.txninfra.com/cprod/",
  // ghi_stage: "https://apidev.iserveu.online/stagingauth/livLong/",
  ghi_prod: "https://apiprod.txninfra.com/cprodV2/livLong/",
  hospicash_stage: "https://apidev.iserveu.online/stagingauth/hospicash/",
  notify_sub:
    // "https://fcmtokensubscription-vn3k2k7q7q-uc.a.run.app/subscribeToTopicByUserid", //old url
    "https://apiprod.txninfra.com/cprodV2/ca/token/subscribeToTopicByUserid",
  notify_unsub:
    // "https://fcmtokensubscription-vn3k2k7q7q-uc.a.run.app/unsubscribeToTopicByUserid", //old url
    "https://apiprod.txninfra.com/cprodV2/ca/token/unsubscribeToTopicByUserid",
};
let logOutTimer = undefined;
export const refershtime = 15;
export function autoLogOut() {

  const tokenData = jwt_decode(sessionStorage.getItem("CORE_SESSION") || "{}");
  const startDate = new Date();
  const expDate = new Date(tokenData.exp * 1000);
  const session = Math.ceil(expDate - startDate);
  const mins = Math.floor(session / 1000 / 60); 
  if (logOutTimer) {
    clearTimeout(logOutTimer);
  }
  logOutTimer = setTimeout(() => { 
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  }, session);
}
export async function refreshapi() {
  try {
    let params = new URLSearchParams();
    params.set("grant_type", "refresh_token");
    params.set("refresh_token", sessionStorage.getItem("refresh_token") || "");
    let response1 = await fetch(
      "https://oauth2-auth-server-common-admin-prod.txninfra.com/oauth/token?" +
      params,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            `Basic ` +
            btoa("common-admins-oauth2-client:common-admins-oauth-password"),
        },
      }
    ).catch((err) => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
    });
    let data = await response1.json();
    sessionStorage.setItem("access_token", data.access_token);
    sessionStorage.setItem("CORE_SESSION", data.access_token);
    sessionStorage.setItem("refresh_token", data.refresh_token);
    autoLogOut();
  } catch (err) {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  }
}
let intervaltime = undefined;
export let notificationData = new BehaviorSubject({});
export async function notification() {
  if (intervaltime == undefined) {
    notificationfun();
  }

  intervaltime = setInterval(async () => {
    notificationfun();
  }, 5 * 60 * 1000);
}
export function clearnotifyinterval() {
  if (intervaltime) {
    notificationData.next({});
    clearTimeout(intervaltime);
  }
}
export async function notificationfun() {
  try {
    const reqBody = {
      user_name: JSON.parse(sessionStorage.getItem("dashboardData")).userInfo
        .userName,
      product_name: "Global",
      limit: 30,
      page: 1,
      type: "Transactional",
    };
    let response = await fetch(
      // "https://apidev.iserveu.online/stagingauth/common/fetch_notification", //old url
      "https://api-prod.txninfra.com/encrV3/ca/notification/fetch_notification",
      {
        method: "POST",
        // body:reqBody, 
        body:JSON.stringify(encryptResponse(JSON.stringify({
          user_name: JSON.parse(sessionStorage.getItem("dashboardData"))
            .userInfo.userName,
          product_name: "Global",
          limit: 30,
          page: 1,
          type: "Transactional",
        }))),
        headers: {
          "content-type": "application/json",
          Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
          pass_key: 'MQxplW763gfSDOwm2aLmCOFXQYGSWtsnvCfkat58j8AFl0xW'
        },
      }
    ).catch((err) => { 
      if(validate_exp()){
        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.setItem('err_fetch_noti', 'yes');
        clearnotifyinterval();
        window.location.href = "/login"; 
      }
    });
    let data = await response.json(); 
    if (data.status === 0) {
      notificationData.next(data);
    } else {
      notificationData.next({})
    }
  } catch (error) { 
    if(validate_exp()){
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('err_fetch_noti', 'yes');
      window.location.href = "/login"; 
    }
  }
}

export const adminConfig = [
  { adminName: "technewadmin", canEnableUser: "false" },
  { adminName: "babloow84", canEnableUser: "false" },
  { adminName: "stratonw522", canEnableUser: "false" },
  { adminName: "arthpayw494", canEnableUser: "false" },
  { adminName: "demoisu", canEnableUser: "false" },
  { adminName: "paysallwlw523", canEnableUser: "false" },
  { adminName: "janpaisaw24", canEnableUser: "false" },
  { adminName: "acultwl527", canEnableUser: "false" },
  { adminName: "meharw529", canEnableUser: "false" },
  { adminName: "subkw528", canEnableUser: "false" },
  { adminName: "synergyw532", canEnableUser: "false" },
];
export let kycStatus = new BehaviorSubject();
export function fetchUserData() {
  const authorizationToken = sessionStorage.getItem("CORE_SESSION");
  const apiUrl =
    "https://user-registration-common-admin-prod.txninfra.com/user/dashboard";
  const headers = {
    Authorization: `${authorizationToken}`,
  };
  fetch(apiUrl, {
    headers: headers,
  })
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Parse the response JSON data
      return response.json();
    })
    .then((data) => {
      kycStatus.next(data.userInfo.userProfile.kycActiveStatus);
      sessionStorage.setItem("dashboardData", JSON.stringify(data));
    })
    .catch((error) => { 
    });

}
const allAdmin_details = {
  arthpaytech: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/storeimg.png",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/arthpaylogo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: false,
    talk2us: true,
    arthpayContent: true,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/root-assets/ARTH%20PAY_logo.png",
    tabName: "ArthPay",
    colorIndex: 0,
    adminName:"arthpayw494"
  },
  mydigitalpayment: {
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/tonav_babloo_logo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: true,
    talk2us: false,
    colorIndex: 0,
    favUrl:"https://storage.googleapis.com/micro-services-frontend/dist/assets/favicon.gif",
    tabName: "Common Admin", 
    adminName:"technewadmin"
  },
  'common-admin': {
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/tonav_babloo_logo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: true,
    talk2us: false,
    colorIndex: 0,
    favUrl:"https://storage.googleapis.com/micro-services-frontend/dist/assets/favicon.gif",
    tabName: "Common Admin",
  },
  digitalsewa: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/digitalsewashop.jpeg",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:"https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/digitalsewashop.jpeg",
    tabName: "DIGITAL SEWA SHOP",
    colorIndex: 0,
    adminName:"babloow84"
  },
  stratonfinserv: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/start-on-logo.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/start-on-logo.png",
    tabName: "Starton Finserv",
    colorIndex: 0,
    adminName:"stratonw522"
  },
  biharipay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/bihari-pay.jpg",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/bihari-pay.jpg",
    tabName: "Bihari Pay",
    colorIndex: 0,
    adminName:"biharipyw522"
  },
  paysallbanking: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/Paysall%20Banking%20Final%20Logo%20-%20Board%20Mytrip.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/Paysall%20Banking%20Final%20Logo%20-%20Board%20Mytrip.png",
    tabName: "Pays All Banking",
    colorIndex: 0,
    adminName:"paysallwlw523"
  },
  bbpay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/BBT%20-%20shiju%20BB.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/BBT%20-%20shiju%20BB.png",
    tabName: "BBPAY",
    colorIndex: 1,
    adminName:"bbpay"
  },
  janpaisa: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/jan_paisa.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/jan_paisa.png",
    tabName: "Jan Paisa",
    colorIndex: 0,
    adminName:"janpaisaw24"
  },
  acultpay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Acultpay%20-%20Praveen%20Kumar%20R.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Acultpay%20-%20Praveen%20Kumar%20R.png",
    tabName: "Acult Pay",
    colorIndex: 1,
    adminName:"acultwl527"
  },
  cooppay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/coopay.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/coopay.png",
    tabName: "COOPPAY MERCHANT",
    privacyPolicy: true,
    colorIndex: 0,
    adminName:"meharw529"
  },
  subk: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Sub-K%20logo.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Sub-K%20logo.png",
    tabName: "Subk Pay",
    colorIndex: 1,
    adminName:"subkw528"
  },
  somriddhidigital: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/samridhi.jpg",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/samridhi.jpg",
    tabName: "Somriddhi Digital",
    colorIndex: 1,
    adminName:"sdplw530"
  },
  localhost: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/storeimg.png",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/arthpaylogo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: true,
    talk2us: true,
    arthpayContent: true,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/favicon.gif",
    tabName: "Common Admin",
    colorIndex: 0
  },
  payufi: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payUFI.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payUFI.png",
    tabName: "PayUFI",
    colorIndex: 0,
    adminName:"synergyw532"
  },
  digisevapay:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Digi%20Seva%20Logo.png",
  commonOnboarding: true,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Digi%20Seva%20Logo.png",
  tabName: "digisevapay",
  colorIndex: 0,
  adminName:"digisevapy2w233"
  },
  easycashpoint:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/EasyPointCash.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/EasyPointCash.png",
  tabName: "easycashpoint",
  colorIndex: 0,
  adminName:"nrecashw127"
  },
  'paym.co':{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/PAY%20M.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/PAY%20M.png",
  tabName: "Paym Tech",
  colorIndex: 0,
  adminName:"paymtechw533"
  },
  acashpay:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/acashpay%20PNG%20-%20Toshani%20Management.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/acashpay%20PNG%20-%20Toshani%20Management.png",
  tabName: "acashpay",
  colorIndex: 0,
  adminName:"acashpayw534"
  },
  wizaker:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/WK%20NEW%20LOGO.jpg",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/WK%20NEW%20LOGO.jpg",
  tabName: "wizaker",
  colorIndex: 0,
  adminName:"wizconsw519"
  },
  polskaindia:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Polska%20Logo.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Polska%20Logo.png",
  tabName: "polskaindia",
  colorIndex: 0,
  adminName:"polskaneww282"
  },
  glpay:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/glpay.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/glpay.png",
  tabName: "GLPay",
  colorIndex: 0,
  adminName:"glpayneww290"
  },
uinipay:{ 
  loginImgUrl: 
  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
  topnavLogo: 
  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/uinipay.jpeg", 
  commonOnboarding: false, 
  showApproval:false, 
  dashboard: true, 
  talk2us: false, 
  favUrl: 
  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/uinipay.jpeg", 
  tabName: "UINIPAY", 
  colorIndex: 0,
  adminName:"uinipayw536" 
  },
  payfreee:{ 
    loginImgUrl: 
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
    topnavLogo: 
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payfreee.png", 
    commonOnboarding: false, 
    showApproval:false, 
    dashboard: true, 
    talk2us: false, 
    favUrl: 
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payfreee.png", 
    tabName: "PAYFREEE", 
    colorIndex: 0,
    adminName:"payfreeew537" 
    },
    riyopay:{ 
      loginImgUrl: 
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
      topnavLogo: 
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/riyopay.png", 
      commonOnboarding: false, 
      showApproval:false, 
      dashboard: true, 
      talk2us: false, 
      favUrl: 
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/riyopay.png", 
      tabName: "RIYOPAY", 
      colorIndex: 0,
      adminName:"riyopayw538" 
      },
      'xpay.life':{ 
        loginImgUrl: 
        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
        topnavLogo: 
        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Xpaylife.png", 
        commonOnboarding: false, 
        showApproval:false, 
        dashboard: true, 
        talk2us: false, 
        favUrl: 
        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Xpaylife.png", 
        tabName: "XpayLife", 
        colorIndex: 0,
        adminName:"xpaylifew535" 
        },
        shubhmoney:{ 
          loginImgUrl: 
          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
          topnavLogo: 
          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/shubhMoney.png", 
          commonOnboarding: false, 
          showApproval:false, 
          dashboard: true, 
          talk2us: false, 
          favUrl: 
          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/shubhMoney.png", 
          tabName: "ShubhMoney", 
          colorIndex: 0,
          adminName:"shubhmnyw136" 
          },
          pinkpay:{ 
            loginImgUrl: 
            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
            topnavLogo: 
            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/pinkPay.png", 
            commonOnboarding: false, 
            showApproval:false, 
            dashboard: true, 
            talk2us: false, 
            favUrl: 
            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/pinkPay.png", 
            tabName: "PinkPay", 
            colorIndex: 0,
            adminName:"payhivew539" 
            },
          sahaj:{ 
            loginImgUrl: 
            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
            topnavLogo: 
            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/sahaj.png", 
            commonOnboarding: true, 
            showApproval:false, 
            dashboard: true, 
            talk2us: false, 
            favUrl: 
            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/sahaj.png", 
            tabName: "sahajPay", 
            colorIndex: 0,
            adminName:"sahajwlw540" 
            },
            jaldipay:{ 
              loginImgUrl: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
              topnavLogo: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/jaldi-pay.png", 
              commonOnboarding: false, 
              showApproval:false, 
              dashboard: true, 
              talk2us: false, 
              favUrl: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/jaldi-pay.png", 
              tabName: "jaldiPay", 
              colorIndex: 0,
              adminName:"virtualw541" 
              },
              u2paycash:{ 
                loginImgUrl: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                topnavLogo: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/u2paycash.jpg", 
                commonOnboarding: false, 
                showApproval:false, 
                dashboard: true, 
                talk2us: false, 
                favUrl: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/u2paycash.jpg", 
                tabName: "U2PayCash", 
                colorIndex: 0,
                adminName:"u2paycapinew" 
                },
                kwikpay:{ 
                  loginImgUrl: 
                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                  topnavLogo: 
                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/kwikpay.jpeg", 
                  commonOnboarding: false, 
                  showApproval:false, 
                  dashboard: true, 
                  talk2us: false, 
                  favUrl: 
                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/kwikpay.jpeg", 
                  tabName: "Kwik Pay", 
                  colorIndex: 0,
                  adminName:"splapiupdated" 
                  },
                  bonpay:{ 
                    loginImgUrl: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                    topnavLogo: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/bonpay.png", 
                    commonOnboarding: false, 
                    showApproval:false, 
                    dashboard: true, 
                    talk2us: false, 
                    favUrl: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/bonpay.png", 
                    tabName: "Bonpay", 
                    colorIndex: 0,
                    adminName:"agdpw542" 
                    },
                    instantpayments:{ 
                      loginImgUrl: 
                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                      topnavLogo: 
                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/instantPayment.jpg", 
                      commonOnboarding: false, 
                      showApproval:false, 
                      dashboard: true, 
                      talk2us: false, 
                      favUrl: 
                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/instantPayment.jpg", 
                      tabName: "InstantPayments", 
                      colorIndex: 0,
                      adminName:"krisanw70" 
                      },
            annupay:{ 
              loginImgUrl: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
              topnavLogo: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/annupay.jpeg", 
              commonOnboarding: false, 
              showApproval:false, 
              dashboard: true, 
              talk2us: false, 
              favUrl: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/annupay.jpeg", 
              tabName: "Annu Pay", 
              colorIndex: 0,
              adminName:"annupayw311" 
              },
              neipay:{ 
                loginImgUrl: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                topnavLogo: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/neiPay.jpg", 
                commonOnboarding: false, 
                showApproval:false, 
                dashboard: true, 
                talk2us: false, 
                favUrl: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/neiPay.jpg", 
                tabName: "Neipay", 
                colorIndex: 0,
                adminName:"neipayneww511" 
                },
            emarks:{ 
              loginImgUrl: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
              topnavLogo: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/eMarks.png", 
              commonOnboarding: false, 
              showApproval:false, 
              dashboard: true, 
              talk2us: false, 
              favUrl: 
              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/eMarks.png", 
              tabName: "EMARK", 
              colorIndex: 0,
              adminName:"diyamultineww11" 
              },
              paydigimoney:{ 
                loginImgUrl: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                topnavLogo: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payDigimoney.png", 
                commonOnboarding: false, 
                showApproval:false, 
                dashboard: true, 
                talk2us: false, 
                favUrl: 
                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payDigimoney.png", 
                tabName: "PAY DIGI MONEY", 
                colorIndex: 0,
                adminName:"pdmgcneww174" 
                },
                mcspoint:{ 
                  loginImgUrl: 
                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                  topnavLogo: 
                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/mcsPay.jpeg", 
                  commonOnboarding: false, 
                  showApproval:false, 
                  dashboard: true, 
                  talk2us: false, 
                  favUrl: 
                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/mcsPay.jpeg", 
                  tabName: "MCSP", 
                  colorIndex: 0,
                  adminName:"mayaracsneww204" 
                  },
                  nowpe:{ 
                    loginImgUrl: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                    topnavLogo: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/NowPay.jpg", 
                    commonOnboarding: false, 
                    showApproval:false, 
                    dashboard: true, 
                    talk2us: false, 
                    favUrl: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/NowPay.jpg", 
                    tabName: "Nowpe", 
                    colorIndex: 0,
                    adminName:"nowpeneww436" 
                    },
                    kioskpe:{ 
                    loginImgUrl: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                    topnavLogo: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/KiskoPay.jpg", 
                    commonOnboarding: false, 
                    showApproval:false, 
                    dashboard: true, 
                    talk2us: false, 
                    favUrl: 
                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/KiskoPay.jpg", 
                    tabName: "KioskPe", 
                    colorIndex: 0,
                    adminName:"kioskpyneww291" 
                    },
                    anubhutisoft:{ 
                      loginImgUrl: 
                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                      topnavLogo: 
                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/AnubhutiSoft.jpg", 
                      commonOnboarding: false, 
                      showApproval:false, 
                      dashboard: true, 
                      talk2us: false, 
                      favUrl: 
                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/AnubhutiSoft.jpg", 
                      tabName: "Anubhuti Soft", 
                      colorIndex: 0,
                      adminName:"anubhutineww423" 
                      },
                      khusiepay:{ 
                        loginImgUrl: 
                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                        topnavLogo: 
                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/khusipay.jpg", 
                        commonOnboarding: false, 
                        showApproval:false, 
                        dashboard: true, 
                        talk2us: false, 
                        favUrl: 
                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/khusipay.jpg", 
                        tabName: "khusiconcept", 
                        colorIndex: 0,
                        adminName:"khusineww330" 
                        },
                        smartpointindia:{ 
                          loginImgUrl: 
                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                          topnavLogo: 
                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/smartpointindia.jpg", 
                          commonOnboarding: false, 
                          showApproval:false, 
                          dashboard: true, 
                          talk2us: false, 
                          favUrl: 
                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/smartpointindia.jpg", 
                          tabName: "smartpointindia", 
                          colorIndex: 0,
                          adminName:"alomneww78" 
                          },
                          dbpay:{ 
                            loginImgUrl: 
                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                            topnavLogo: 
                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/DBPAY.png", 
                            commonOnboarding: false, 
                            showApproval:false, 
                            dashboard: true, 
                            talk2us: false, 
                            favUrl: 
                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/DBPAY.png", 
                            tabName: "DBPAY", 
                            colorIndex: 0,
                            adminName:"dbpayneww268" 
                            },
                            paysguru:{ 
                              loginImgUrl: 
                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                              topnavLogo: 
                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Paysguru.png", 
                              commonOnboarding: false, 
                              showApproval:false, 
                              dashboard: true, 
                              talk2us: false, 
                              favUrl: 
                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Paysguru.png", 
                              tabName: "Paysguru", 
                              colorIndex: 0,
                              adminName:"skriptoncw543" 
                              },
                              psm2pay:{ 
                                loginImgUrl: 
                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                topnavLogo: 
                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/psm2pay.jpg", 
                                commonOnboarding: false, 
                                showApproval:false, 
                                dashboard: true, 
                                talk2us: false, 
                                favUrl: 
                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/psm2pay.jpg", 
                                tabName: "PSM2PAY", 
                                colorIndex: 0,
                                adminName:"psm2payneww292" 
                                },
                                paydhara:{ 
                                  loginImgUrl: 
                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/paydhara.png", 
                                  topnavLogo: 
                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/paydhara.png", 
                                  commonOnboarding: false, 
                                  showApproval:false, 
                                  dashboard: true, 
                                  talk2us: false, 
                                  favUrl: 
                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/paydhara.png", 
                                  tabName: "Paydhara", 
                                  colorIndex: 0,
                                  adminName:"paydharaw544" 
                                  },
                                  bigwayz:{ 
                                    loginImgUrl: 
                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                    topnavLogo: 
                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Bigwayz.png", 
                                    commonOnboarding: false, 
                                    showApproval:false, 
                                    dashboard: true, 
                                    talk2us: false, 
                                    favUrl: 
                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Bigwayz.png", 
                                    tabName: "bigwayz", 
                                    colorIndex: 0,
                                    adminName:"bigwayzneww179" 
                                    },
                                    iservepay:{ 
                                      loginImgUrl: 
                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                      topnavLogo: 
                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/iservepay.jpg", 
                                      commonOnboarding: false, 
                                      showApproval:false, 
                                      dashboard: true, 
                                      talk2us: false, 
                                      favUrl: 
                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/iservepay.jpg", 
                                      tabName: "iservepay", 
                                      colorIndex: 0,
                                      adminName:"habizw103" 
                                      },
                                      svdigitalpay:{ 
                                        loginImgUrl: 
                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                        topnavLogo: 
                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/svDigital.png", 
                                        commonOnboarding: false, 
                                        showApproval:false, 
                                        dashboard: true, 
                                        talk2us: false, 
                                        favUrl: 
                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/svDigital.png", 
                                        tabName: "SV DIGITAL PAY", 
                                        colorIndex: 0,
                                        adminName:"svdigiw347" 
                                        },
                                        edigitalindiaservice:{ 
                                          loginImgUrl: 
                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                          topnavLogo: 
                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/eDigitalindiaService.jpg", 
                                          commonOnboarding: false, 
                                          showApproval:false, 
                                          dashboard: true, 
                                          talk2us: false, 
                                          favUrl: 
                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/eDigitalindiaService.jpg", 
                                          tabName: "Edigitalindiaservice", 
                                          colorIndex: 0,
                                          adminName:"edigitalw390" 
                                          },
                                          shubhpay:{ 
                                            loginImgUrl: 
                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                            topnavLogo: 
                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/shubhMoney.png", 
                                            commonOnboarding: false, 
                                            showApproval:false, 
                                            dashboard: true, 
                                            talk2us: false, 
                                            favUrl: 
                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/shubhMoney.png", 
                                            tabName: "SHUBHMONEY", 
                                            colorIndex: 0,
                                            adminName:"shubhw136" 
                                            },
                                            nictpay:{ 
                                              loginImgUrl: 
                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                              topnavLogo: 
                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/nictpay.jpeg", 
                                              commonOnboarding: false, 
                                              showApproval:false, 
                                              dashboard: true, 
                                              talk2us: false, 
                                              favUrl: 
                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/nictpay.jpeg", 
                                              tabName: "NICT PAY", 
                                              colorIndex: 0,
                                              adminName:"nictw129" 
                                              },
                                              gazipay:{ 
                                                loginImgUrl: 
                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                topnavLogo: 
                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/gizapay.jpg", 
                                                commonOnboarding: false, 
                                                showApproval:false, 
                                                dashboard: true, 
                                                talk2us: false, 
                                                favUrl: 
                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/gizapay.jpg", 
                                                tabName: "Gazitravels", 
                                                colorIndex: 0,
                                                adminName:"gazipayw135" 
                                                },
                                                sowjanyachains:{ 
                                                  loginImgUrl: 
                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                  topnavLogo: 
                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/acashworld.jpg", 
                                                  commonOnboarding: false, 
                                                  showApproval:false, 
                                                  dashboard: true, 
                                                  talk2us: false, 
                                                  favUrl: 
                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/acashworld.jpg", 
                                                  tabName: "Achains", 
                                                  colorIndex: 0,
                                                  adminName:"acash" 
                                                  },
                                                  reijiro:{ 
                                                    loginImgUrl: 
                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                    topnavLogo: 
                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/rjpay.jpg", 
                                                    commonOnboarding: false, 
                                                    showApproval:false, 
                                                    dashboard: true, 
                                                    talk2us: false, 
                                                    favUrl: 
                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/rjpay.jpg", 
                                                    tabName: "Reijiro", 
                                                    colorIndex: 0,
                                                    adminName:"reijirow181" 
                                                    },
                                                    apnadigikendra:{ 
                                                      loginImgUrl: 
                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                      topnavLogo: 
                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/apnadgkendra.jpg", 
                                                      commonOnboarding: false, 
                                                      showApproval:false, 
                                                      dashboard: true, 
                                                      talk2us: false, 
                                                      favUrl: 
                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/apnadgkendra.jpg", 
                                                      tabName: "APNA DIGI KENDRA", 
                                                      colorIndex: 0,
                                                      adminName:"apnadigiw313" 
                                                      },
                                                      nesapay:{ 
                                                        loginImgUrl: 
                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                        topnavLogo: 
                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/nesapay.jpeg", 
                                                        commonOnboarding: false, 
                                                        showApproval:false, 
                                                        dashboard: true, 
                                                        talk2us: false, 
                                                        favUrl: 
                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/nesapay.jpeg", 
                                                        tabName: "NESAPAY ", 
                                                        colorIndex: 0,
                                                        adminName:"nesaw380" 
                                                        },
                                                        ssdinfotech:{ 
                                                          loginImgUrl: 
                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                          topnavLogo: 
                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/sdpay.jpeg", 
                                                          commonOnboarding: false, 
                                                          showApproval:false, 
                                                          dashboard: true, 
                                                          talk2us: false, 
                                                          favUrl: 
                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/sdpay.jpeg", 
                                                          tabName: "SDPAY ", 
                                                          colorIndex: 0,
                                                          adminName:"newpyw219" 
                                                          },
                                                          'pay-karo':{ 
                                                            loginImgUrl: 
                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                            topnavLogo: 
                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Pay-karo.png", 
                                                            commonOnboarding: false, 
                                                            showApproval:false, 
                                                            dashboard: true, 
                                                            talk2us: false, 
                                                            favUrl: 
                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Pay-karo.png", 
                                                            tabName: "Pay-Karo Business Solutions Pvt. Ltd.", 
                                                            colorIndex: 0,
                                                            adminName:"paykarow306" 
                                                            },
                                                            nkpays:{ 
                                                            loginImgUrl: 
                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                            topnavLogo: 
                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/nkpays.png", 
                                                            commonOnboarding: false, 
                                                            showApproval:false, 
                                                            dashboard: true, 
                                                            talk2us: false, 
                                                            favUrl: 
                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/nkpays.png", 
                                                            tabName: "Nkpays BC", 
                                                            colorIndex: 0,
                                                            adminName:"nkpaysw513" 
                                                            },
                                                            ssptrading:{ 
                                                              loginImgUrl: 
                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                              topnavLogo: 
                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/sstrade.jpeg", 
                                                              commonOnboarding: false, 
                                                              showApproval:false, 
                                                              dashboard: true, 
                                                              talk2us: false, 
                                                              favUrl: 
                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/sstrade.jpeg", 
                                                              tabName: "SSP Trading", 
                                                              colorIndex: 0,
                                                              adminName:"ssptradw162" 
                                                              },
                                                              suvidhamart:{ 
                                                                loginImgUrl: 
                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                topnavLogo: 
                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/suvidhamart.jpeg", 
                                                                commonOnboarding: false, 
                                                                showApproval:false, 
                                                                dashboard: true, 
                                                                talk2us: false, 
                                                                favUrl: 
                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/suvidhamart.jpeg", 
                                                                tabName: "Suvidha Mart", 
                                                                colorIndex: 0,
                                                                adminName:"suvidha" 
                                                                },
                                                                anytimemoneyonline:{ 
                                                                  loginImgUrl: 
                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                  topnavLogo: 
                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ATMoney.jpeg", 
                                                                  commonOnboarding: false, 
                                                                  showApproval:false, 
                                                                  dashboard: true, 
                                                                  talk2us: false, 
                                                                  favUrl: 
                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ATMoney.jpeg", 
                                                                  tabName: "ATMoney", 
                                                                  colorIndex: 0,
                                                                  adminName:"allin1w173" 
                                                                  },
                                                                  ucash:{ 
                                                                    loginImgUrl: 
                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                    topnavLogo: 
                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ucashlogo.jpeg", 
                                                                    commonOnboarding: false, 
                                                                    showApproval:false, 
                                                                    dashboard: true, 
                                                                    talk2us: false, 
                                                                    favUrl: 
                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ucashlogo.jpeg", 
                                                                    tabName: "Ucash", 
                                                                    colorIndex: 0,
                                                                    adminName:"Ucashnew" 
                                                                    },
                                                                    erosmoney:{ 
                                                                      loginImgUrl: 
                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                      topnavLogo: 
                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ErosMoney.jpg", 
                                                                      commonOnboarding: false, 
                                                                      showApproval:false, 
                                                                      dashboard: true, 
                                                                      talk2us: false, 
                                                                      favUrl: 
                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ErosMoney.jpg", 
                                                                      tabName: "Eros Money Services", 
                                                                      colorIndex: 0,
                                                                      adminName:"erosw324" 
                                                                      },
                                                                      raspay:{ 
                                                                        loginImgUrl: 
                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                        topnavLogo: 
                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/RASPAY.jpg", 
                                                                        commonOnboarding: false, 
                                                                        showApproval:false, 
                                                                        dashboard: true, 
                                                                        talk2us: false, 
                                                                        favUrl: 
                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/RASPAY.jpg", 
                                                                        tabName: "RASPAY MONEY", 
                                                                        colorIndex: 0,
                                                                        adminName:"aiitsw120" 
                                                                        },
                                                                        payonic:{ 
                                                                          loginImgUrl: 
                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                          topnavLogo: 
                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payonic.png", 
                                                                          commonOnboarding: false, 
                                                                          showApproval:false, 
                                                                          dashboard: true, 
                                                                          talk2us: false, 
                                                                          favUrl: 
                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payonic.png", 
                                                                          tabName: "Payonic", 
                                                                          colorIndex: 0,
                                                                          adminName:"payonicw193" 
                                                                          },
                                                                          indiapayhere:{ 
                                                                            loginImgUrl: 
                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                            topnavLogo: 
                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Indiapay.jpg", 
                                                                            commonOnboarding: false, 
                                                                            showApproval:false, 
                                                                            dashboard: true, 
                                                                            talk2us: false, 
                                                                            favUrl: 
                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Indiapay.jpg", 
                                                                            tabName: "India Pay Here", 
                                                                            colorIndex: 0,
                                                                            adminName:"indiapayw240" 
                                                                            },
                                                                            banksakhi:{ 
                                                                              loginImgUrl: 
                                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                              topnavLogo: 
                                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/banksakhi.png", 
                                                                              commonOnboarding: false, 
                                                                              showApproval:false, 
                                                                              dashboard: true, 
                                                                              talk2us: false, 
                                                                              favUrl: 
                                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/banksakhi.png", 
                                                                              tabName: "Banksakhi", 
                                                                              colorIndex: 0,
                                                                              adminName:"suniveshw329" 
                                                                              },
                                                                              measypay:{ 
                                                                                loginImgUrl: 
                                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                topnavLogo: 
                                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Measypay.jpeg", 
                                                                                commonOnboarding: false, 
                                                                                showApproval:false, 
                                                                                dashboard: true, 
                                                                                talk2us: false, 
                                                                                favUrl: 
                                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Measypay.jpeg", 
                                                                                tabName: "MEASYPAY TECHNOLOGY", 
                                                                                colorIndex: 0,
                                                                                adminName:"measyw322" 
                                                                                },
                                                                                gsgswipe:{ 
                                                                                  loginImgUrl: 
                                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                  topnavLogo: 
                                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Gsgswipe.png", 
                                                                                  commonOnboarding: false, 
                                                                                  showApproval:false, 
                                                                                  dashboard: true, 
                                                                                  talk2us: false, 
                                                                                  favUrl: 
                                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Gsgswipe.png", 
                                                                                  tabName: "GSG Swipe", 
                                                                                  colorIndex: 0,
                                                                                  adminName:"gsgswipew215" 
                                                                                  },
                                                                                  paymnc:{ 
                                                                                    loginImgUrl: 
                                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                    topnavLogo: 
                                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/MNCCare.jpg", 
                                                                                    commonOnboarding: false, 
                                                                                    showApproval:false, 
                                                                                    dashboard: true, 
                                                                                    talk2us: false, 
                                                                                    favUrl: 
                                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/MNCCare.jpg", 
                                                                                    tabName: "MNC Care", 
                                                                                    colorIndex: 0,
                                                                                    adminName:"mncw76" 
                                                                                    },
                                                                                    seeamdigital:{ 
                                                                                      loginImgUrl: 
                                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                      topnavLogo: 
                                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/CMdigital.png", 
                                                                                      commonOnboarding: false, 
                                                                                      showApproval:false, 
                                                                                      dashboard: true, 
                                                                                      talk2us: false, 
                                                                                      favUrl: 
                                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/CMdigital.png", 
                                                                                      tabName: "CM ENTERPRISE", 
                                                                                      colorIndex: 0,
                                                                                      adminName:"cmdigiw408" 
                                                                                      },
                                                                                      "ro-money":{ 
                                                                                        loginImgUrl: 
                                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                        topnavLogo: 
                                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ROmoney.png", 
                                                                                        commonOnboarding: false, 
                                                                                        showApproval:false, 
                                                                                        dashboard: true, 
                                                                                        talk2us: false, 
                                                                                        favUrl: 
                                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ROmoney.png", 
                                                                                        tabName: "RO Money", 
                                                                                        colorIndex: 0,
                                                                                        adminName:"romoneyw516" 
                                                                                        },
                                                                                        cybercareinfoways:{ 
                                                                                          loginImgUrl: 
                                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                          topnavLogo: 
                                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Swipr.png", 
                                                                                          commonOnboarding: false, 
                                                                                          showApproval:false, 
                                                                                          dashboard: true, 
                                                                                          talk2us: false, 
                                                                                          favUrl: 
                                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Swipr.png", 
                                                                                          tabName: "Swipr", 
                                                                                          colorIndex: 0,
                                                                                          adminName:"cybercarw547" 
                                                                                          },
                                                                                          avaafin:{ 
                                                                                            loginImgUrl: 
                                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                            topnavLogo: 
                                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Avaafin.jpeg", 
                                                                                            commonOnboarding: false, 
                                                                                            showApproval:false, 
                                                                                            dashboard: true, 
                                                                                            talk2us: false, 
                                                                                            favUrl: 
                                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Avaafin.jpeg", 
                                                                                            tabName: "Avaafin", 
                                                                                            colorIndex: 0,
                                                                                            adminName:"avaafinw546" 
                                                                                            },
                                                                                            dhcpay:{ 
                                                                                              loginImgUrl: 
                                                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                              topnavLogo: 
                                                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/DHCpay.png", 
                                                                                              commonOnboarding: false, 
                                                                                              showApproval:false, 
                                                                                              dashboard: true, 
                                                                                              talk2us: false, 
                                                                                              favUrl: 
                                                                                              "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/DHCpay.png", 
                                                                                              tabName: "DHCPAY", 
                                                                                              colorIndex: 0,
                                                                                              adminName:"dhcdigiw548" 
                                                                                              },
                                                                                              logicpay:{ 
                                                                                                loginImgUrl: 
                                                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                topnavLogo: 
                                                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/LogicPay.jpeg", 
                                                                                                commonOnboarding: false, 
                                                                                                showApproval:false, 
                                                                                                dashboard: true, 
                                                                                                talk2us: false, 
                                                                                                favUrl: 
                                                                                                "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/LogicPay.jpeg", 
                                                                                                tabName: "Logic Pay", 
                                                                                                colorIndex: 0,
                                                                                                adminName:"logicpyw521" 
                                                                                                },
                                                                                                zobbipaysoftware:{ 
                                                                                                  loginImgUrl: 
                                                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                  topnavLogo: 
                                                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ZpiLogo.png", 
                                                                                                  commonOnboarding: false, 
                                                                                                  showApproval:false, 
                                                                                                  dashboard: true, 
                                                                                                  talk2us: false, 
                                                                                                  favUrl: 
                                                                                                  "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/ZpiLogo.png", 
                                                                                                  tabName: "Zobbi Pay Software", 
                                                                                                  colorIndex: 0,
                                                                                                  adminName:"zobbipayw549" 
                                                                                                  },
                                                                                                  dreamalligned:{ 
                                                                                                    loginImgUrl: 
                                                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                    topnavLogo: 
                                                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/DreamAlign.jpg", 
                                                                                                    commonOnboarding: false, 
                                                                                                    showApproval:false, 
                                                                                                    dashboard: true, 
                                                                                                    talk2us: false, 
                                                                                                    favUrl: 
                                                                                                    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/DreamAlign.jpg", 
                                                                                                    tabName: "Dream Alligned", 
                                                                                                    colorIndex: 0,
                                                                                                    adminName:"dreamallw550" 
                                                                                                    },
                                                                                                    brsolution:{ 
                                                                                                      loginImgUrl: 
                                                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                      topnavLogo: 
                                                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/BrSolution.png", 
                                                                                                      commonOnboarding: false, 
                                                                                                      showApproval:false, 
                                                                                                      dashboard: true, 
                                                                                                      talk2us: false, 
                                                                                                      favUrl: 
                                                                                                      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/BrSolution.png", 
                                                                                                      tabName: "BR SOLUTION", 
                                                                                                      colorIndex: 0,
                                                                                                      adminName:"brsolnw551" 
                                                                                                      },
                                                                                                      acemoney:{ 
                                                                                                        loginImgUrl: 
                                                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                        topnavLogo: 
                                                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/AceMoney.png", 
                                                                                                        commonOnboarding: false, 
                                                                                                        showApproval:false, 
                                                                                                        dashboard: true, 
                                                                                                        talk2us: false, 
                                                                                                        favUrl: 
                                                                                                        "https://storage.googleapis.com/micro-services-frontend/dist/assets/AceMoney.png", 
                                                                                                        tabName: "Radiant Acemoney", 
                                                                                                        colorIndex: 0,
                                                                                                        adminName:"acewarew517" 
                                                                                                        },
                                                                                                        growpee:{ 
                                                                                                          loginImgUrl: 
                                                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                          topnavLogo: 
                                                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/Growpee.jpeg", 
                                                                                                          commonOnboarding: false, 
                                                                                                          showApproval:false, 
                                                                                                          dashboard: true, 
                                                                                                          talk2us: false, 
                                                                                                          favUrl: 
                                                                                                          "https://storage.googleapis.com/micro-services-frontend/dist/assets/Growpee.jpeg", 
                                                                                                          tabName: "GrowPe", 
                                                                                                          colorIndex: 0,
                                                                                                          adminName:"growisew545" 
                                                                                                          },
                                                                                                          i247pay:{ 
                                                                                                            loginImgUrl: 
                                                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg", 
                                                                                                            topnavLogo: 
                                                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/i247pay.png", 
                                                                                                            commonOnboarding: false, 
                                                                                                            showApproval:false, 
                                                                                                            dashboard: true, 
                                                                                                            talk2us: false, 
                                                                                                            favUrl: 
                                                                                                            "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/i247pay.png", 
                                                                                                            tabName: "I247 PAY", 
                                                                                                            colorIndex: 0,
                                                                                                            adminName:"dreamconw552" 
                                                                                                            }
};
export let details;
export let adminName;
for (var index in allAdmin_details) {
  if (web_url.includes(index)) {
    details = allAdmin_details[index]; 
    adminName = index;
  }
} 
function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
export function checkAdmin_insurance() {
  let adminName2 = JSON.parse(sessionStorage.getItem("dashboardData"))?.userInfo
    .adminName;
  if (
    adminName2 === "babloow84" ||
    adminName2 === "technewadmin" ||
    adminName2 === "sdplw530"
  ) {
    return true;
  } else {
    return false;
  }
}
export function validate_exp() {
  let data = sessionStorage.getItem("CORE_SESSION");
  let exp = parseJwt(data); 
  const timestamp = exp.exp;
  const today = new Date();
  const todaytimestamp = today.getTime();
  const timestampInSeconds = Math.floor(todaytimestamp / 1000);
  if(timestampInSeconds<=timestamp){
    return false
  } else {
    return true
  }
  // const date = new Date(timestamp * 1000); // Convert to milliseconds by multiplying by 1000

  // // Extract the components of the date and time
  // const year = date.getFullYear();
  // const month = date.getMonth() + 1; // Month is zero-based, so add 1
  // const day = date.getDate();
  // const hours = date.getHours();
  // const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  // // Create a human-readable date and time string
  // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
 

}
export function checkAdmin_Hospicash_insurance() {
  let adminName3 = JSON.parse(sessionStorage.getItem("dashboardData"))?.userInfo
    .adminName;
  if (
    // adminName2 === "babloow84" ||
    adminName3 === "technewadmin"
    // adminName2 === "sdplw530"
  ) {
    return true;
  } else {
    return false;
  }
}
export function encryptResponse(responseBody) {
  // Generate a random IV
  const iv = CryptoJS.lib.WordArray.random(16);    

  // Decode the key from base64
  const decodedKey = CryptoJS.enc.Base64.parse('MWYztgF/ZcR+XyRwZEn1NpdyYGUnQMuIMDgI6ZWlaNg=');

  // Encrypt the data using AES CBC mode and PKCS7 padding
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(responseBody), decodedKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  // Combine the IV and the encrypted data
  const combined = iv.concat(encrypted.ciphertext);

  // Encode the result as base64
  return {"RequestData":CryptoJS.enc.Base64.stringify(combined)};
}


export function decryptRequest(encryptedString) {
  // Decode the encrypted data from base64
  const byteCipherText = CryptoJS.enc.Base64.parse(encryptedString);

  // Extract the IV and the ciphertext
  const iv = CryptoJS.lib.WordArray.create(byteCipherText.words.slice(0, 4), 16);
  const cipherText = CryptoJS.lib.WordArray.create(byteCipherText.words.slice(4), byteCipherText.sigBytes - 16);

  // Decode the key from base64
  const decodedKey = CryptoJS.enc.Base64.parse('MWYztgF/ZcR+XyRwZEn1NpdyYGUnQMuIMDgI6ZWlaNg=');

  // Decrypt the data using AES CBC mode and PKCS7 padding
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, decodedKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  // Convert the decrypted data to a UTF-8 string and trim any noise
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  return removeNoise(decryptedString);
}


export function removeNoise(data) {
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i] === '}') {
      return data.slice(0, i + 1);
    }
  }
  return data;
} 
